<template>
  <b-overlay
    :show="loading"
    rounded="sm"
  >
    <ValidationObserver ref="updatePersonInfoValidation">
      <b-card-code>
        <h3 class="mb-2">
          Update person info
        </h3>
        <b-row v-if="personInfo">
          <b-col md="5">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Last name"
            >
              <b-form-group label="Last name">
                <b-form-input
                  v-model="personInfo.last_name"
                  placeholder="Last name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="4">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="First name"
            >
              <b-form-group label="First name">
                <b-form-input
                  v-model="personInfo.first_name"
                  placeholder="First name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="3">
            <b-form-group label="Gender">
              <v-select
                v-model="personInfo.gender"
                :options="genders"
                :clearable="false"
                label="name"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="DOB"
            >
              <b-form-group label="DOB (Y-m-d)">
                <b-form-input
                  v-model="personInfo.dob"
                  placeholder="DOB (Y-m-d)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="4">
            <ValidationProvider
              v-slot="{ errors }"
              name="Wedding anniversary"
            >
              <b-form-group label="Wedding anniversary (Y-m-d)">
                <b-form-input
                  v-model="personInfo.wa_day"
                  placeholder="Wedding anniversary (Y-m-d)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="4">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Relationships"
            >
              <b-form-group label="Relationships">
                <v-select
                  v-model="personInfo.relationships"
                  multiple
                  :options="relationships"
                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="2">
            <b-form-group label="Sure dob">
              <b-form-checkbox
                v-model="personInfo.c_dob"
                class="mt-1"
              >
                Sure day
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Sure mob">
              <b-form-checkbox
                v-model="personInfo.c_mob"
                class="mt-1"
              >
                Sure month
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Sure yob">
              <b-form-checkbox
                v-model="personInfo.c_yob"
                class="mt-1"
              >
                Sure year
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Inform dob">
              <b-form-checkbox
                v-model="personInfo.is_inform_dob"
                class="mt-1"
              >
                Inform dob
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Intimate level">
              <v-select
                v-model="personInfo.intimate_level"
                :options="intimate_levels"
                :clearable="false"
                label="name"
              />
            </b-form-group>
          </b-col>
          <!-- New Row -->
          <b-col md="4">
            <b-form-group label="Email">
              <b-form-input
                v-model="personInfo.email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Nickname">
              <b-form-input
                v-model="personInfo.nickname"
                placeholder="Nickname"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Phone">
              <b-form-input
                v-model="personInfo.phone"
                placeholder="Phone"
              />
            </b-form-group>
          </b-col>
          <!-- New Row -->
          <b-col md="3">
            <b-form-group label="Facebook url">
              <b-form-input
                v-model="personInfo.facebook_url"
                placeholder="Facebook url"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Facebook name">
              <b-form-input
                v-model="personInfo.facebook_name"
                placeholder="Facebook name"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Instagram">
              <b-form-input
                v-model="personInfo.instagram"
                placeholder="Instagram"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Identity card number">
              <b-form-input
                v-model="personInfo.identity_card_number"
                placeholder="Identity card number"
              />
            </b-form-group>
          </b-col>
          <!-- New Row -->
          <b-col md="4">
            <b-form-group label="Home address">
              <b-form-input
                v-model="personInfo.home_address"
                placeholder="Home address"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Home address lat">
              <b-form-input
                v-model="personInfo.home_address_lat"
                placeholder="Home address lat"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Home address long">
              <b-form-input
                v-model="personInfo.home_address_long"
                placeholder="Home address long"
              />
            </b-form-group>
          </b-col>
          <!-- New row -->
          <b-col md="12">
            <b-form-group label="Note">
              <b-form-textarea
                v-model="personInfo.note"
                placeholder="Note"
                rows="4"
              />
            </b-form-group>
          </b-col>
          <!-- New row -->
          <b-col
            md="12"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              @click="updatePersonInfoAction"
            >
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-card-code>
    </ValidationObserver>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
// import { required } from '@validations'

const { mapGetters, mapActions } = createNamespacedHelpers('personInfo')

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mixins: [toastification],
  data() {
    return {
      genders: [
        { name: 'Nữ', val: 0 },
        { name: 'Nam', val: 1 },
      ],
      intimate_levels: [
        { name: 'Very important', val: 0 },
        { name: 'Important', val: 1 },
        { name: 'Normal', val: 2 },
        { name: 'Save information', val: 3 },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'message',
      'status',
      'loading',
      'relationships',
      'personInfo',
    ]),
  },
  async created() {
    await this.getPersonInfo(this.$route.params.id)
    await this.initPersonInfoParams()
  },

  methods: {
    ...mapActions(['getRelationships', 'getPersonInfo', 'updatePersonInfo']),
    async initPersonInfoParams() {
      await this.getRelationships()
      this.personInfo.c_dob = this.personInfo.c_dob === 1
      this.personInfo.c_mob = this.personInfo.c_mob === 1
      this.personInfo.c_yob = this.personInfo.c_yob === 1
      this.personInfo.is_inform_dob = this.personInfo.is_inform_dob === 1
      const relationships = this.personInfo.relationship_names.split(',')
      this.relationships.forEach(item => {
        if (relationships.includes(item)) {
          this.personInfo.relationships.push(item)
        }
      })
      this.genders.forEach(item => {
        if (item.val === this.personInfo.gender) {
          this.personInfo.gender = item
        }
      })
      this.intimate_levels.forEach(item => {
        if (item.val === this.personInfo.intimate_level) {
          this.personInfo.intimate_level = item
        }
      })
    },
    async updatePersonInfoAction() {
      const success = await this.$refs.updatePersonInfoValidation.validate()
      if (!success) {
        this.toastFailure('Validation failed')
        return
      }
      const params = { ...this.personInfo }
      params.gender = params.gender.val
      params.c_dob = params.c_dob ? 1 : 0
      params.c_mob = params.c_mob ? 1 : 0
      params.c_yob = params.c_yob ? 1 : 0
      params.intimate_level = params.intimate_level.val
      params.relationships = params.relationships.join(',')

      await this.updatePersonInfo(params)
      if (this.status) {
        this.toastSuccess('Update person info successfully')
      } else {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>
